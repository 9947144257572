import React from 'react'
import RichText from '../../RichText/RichText'
import classnames from 'classnames'

import Button from '../../Button/Button'
import Breakpoint from '../../Breakpoint/Breakpoint'

import linkToPath from '../../../utils/linkToPath'

import { container, image, txt } from './HomeAbout.module.scss'

const HomeAbout = ({ data, className, ...props }) => {
  const { img, link, text } = data

  return (
    <section className={classnames('section grey', className)} {...props}>
      <div className={classnames(container, 'container small')}>
        <Breakpoint showFor={'large'}>
          <div className={image}>
            <img
              src={img.sourceUrl}
              alt={img.altText}
              width={img.mediaDetails.width}
              height={img.mediaDetails.height}
            />
          </div>
        </Breakpoint>
        <div>
          {text && <RichText className={txt}>{text}</RichText>}
          {link && (
            <Button link={linkToPath(link.url)} secondary hollow>
              {link.title}
            </Button>
          )}
        </div>
      </div>
    </section>
  )
}

export default HomeAbout
