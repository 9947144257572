import React from 'react'
import classnames from 'classnames'

import { title } from './SectionTitle.module.scss'

const SectionTitle = ({ children, clasName, ...props }) => {
  return (
    <h2
      {...props}
      className={classnames(title, clasName)}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  )
}

export default SectionTitle
