import React from 'react'
import Particles from 'react-tsparticles'

import RichText from '../RichText/RichText'
import Breakpoint from '../Breakpoint/Breakpoint'
import { setGlobalState } from '../../utils/globalState'

import {
  section,
  container,
  title as titleCss,
  titleCont,
  titleSub,
  text,
  buttons,
  illustration,
  illustrationWrapper,
  particles,
} from './HomeHero.module.scss'
import Button from '../Button/Button'

const HomeHero = ({ data, ...props }) => {
  const { title, subtitle, desc, button1, button2 } = data

  const openModal = () => {
    setGlobalState('showGetStart', true)
  }

  const particlesInit = (main) => {}
  const particlesLoaded = (container) => {}

  return (
    <section className={section} {...props}>
      <div className={container}>
        <div>
          {(title || subtitle) && (
            <div className={titleCont}>
              {title && <h1 className={titleCss}>{title}</h1>}
              {subtitle && <div className={titleSub}>{subtitle}</div>}
            </div>
          )}
          {desc && <RichText className={text}>{desc}</RichText>}
          {(button1 || button2) && (
            <div className={buttons}>
              {button1 && (
                <Button
                  secondary
                  scrollTo={'services'}
                  offset={-80}
                  smooth={true}
                >
                  {button1}
                </Button>
              )}
              {button2 && (
                <Button secondary hollow white onClick={openModal}>
                  {button2}
                </Button>
              )}
            </div>
          )}
        </div>
        <Breakpoint showFor={'large'}>
          <div className={illustration}>
            <div className={illustrationWrapper} />
          </div>
        </Breakpoint>
      </div>
      <Particles
        className={particles}
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fullScreen: {
            enable: false,
          },
          background: {
            color: {
              value: 'none',
            },
          },
          fpsLimit: 60,
          interactivity: {
            detectsOn: 'window',
            events: {
              onClick: {
                enable: true,
                mode: 'push',
              },
              onHover: {
                enable: true,
                mode: 'repulse',
              },
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 2,
                opacity: 0.8,
                size: 40,
              },
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: '#ffffff',
            },
            links: {
              color: '#ffffff',
              distance: 100,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: 'none',
              enable: true,
              outMode: 'bounce',
              random: true,
              speed: 3,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 400,
              },
              value: 40,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: 'circle',
            },
            size: {
              value: 2,
            },
          },
          detectRetina: true,
        }}
      />
    </section>
  )
}

export default HomeHero
