import React from 'react'

import SectionTitle from '../../SectionTitle/SectionTitle'
import Button from '../../Button/Button'

import linkToPath from '../../../utils/linkToPath'

import {
  grid,
  card,
  cardIcon,
  cardTitle,
  cardText,
  cardButton,
} from './HomeServices.module.scss'

const HomeServices = ({ data, ...props }) => {
  const { title, services } = data

  return (
    <section className={'section'} id={'services'} {...props}>
      <div className={'container'}>
        {title && <SectionTitle>{title}</SectionTitle>}
        {services && (
          <div className={grid}>
            {services.map((srv, idx) => {
              const { icon, title, desc, link } = srv

              return (
                <div key={idx} className={card}>
                  <img
                    className={cardIcon}
                    src={icon.sourceUrl}
                    alt={`${title} icon`}
                    width={icon.mediaDetails.width}
                    height={icon.mediaDetails.height}
                    loading={'lazy'}
                  />
                  <h3 className={cardTitle}>{title}</h3>
                  <div className={cardText}>{desc}</div>
                  <div className={cardButton}>
                    <Button link={linkToPath(link.url)} hollow>
                      {link.title}
                    </Button>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </section>
  )
}

export default HomeServices
