// extracted by mini-css-extract-plugin
export var card = "HomeSectors-module--card--0Rw5r";
export var cardTitle = "HomeSectors-module--cardTitle--XNMmN";
export var grid = "HomeSectors-module--grid--J8kny";
export var lineLink = "HomeSectors-module--line-link--fEdrv";
export var lineLink_black = "HomeSectors-module--line-link_black--GSxf2";
export var lineLink_grey = "HomeSectors-module--line-link_grey--3cV-c";
export var lineLink_primary = "HomeSectors-module--line-link_primary--KRATF";
export var lineLink_secondary = "HomeSectors-module--line-link_secondary--BZgdP";
export var lineLink_white = "HomeSectors-module--line-link_white--d1Wih";
export var section = "HomeSectors-module--section--4K3yX";