import React from 'react'
import classnames from 'classnames'

import SectionTitle from '../../SectionTitle/SectionTitle'

import { section, grid, card, cardTitle } from './HomeSectors.module.scss'

const HomeSectors = ({ data, ...props }) => {
  const { title, sectors } = data

  return (
    <section className={classnames('section', section)} {...props}>
      <div className={'container'}>
        {title && <SectionTitle>{title}</SectionTitle>}
        {sectors && (
          <div className={grid}>
            {sectors.map((sec, idx) => {
              const { text, icon } = sec

              return (
                <div className={card} key={idx}>
                  <img
                    src={icon.sourceUrl}
                    alt={`${title} icon`}
                    width={icon.mediaDetails.width}
                    height={icon.mediaDetails.height}
                    loading={'lazy'}
                  />
                  <h3 className={cardTitle}>{text}</h3>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </section>
  )
}

export default HomeSectors
