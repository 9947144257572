const linkToPath = (link) => {
  const isLink = link.substring(0, 4) === 'http'

  if (isLink) {
    const url = new URL(link)
    return url.pathname
  }

  return link
}

export default linkToPath
