import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import Seo from '../components/Seo/Seo'
import HomeHero from '../components/HomeHero/HomeHero'
import HomeAbout from '../components/Home/About/HomeAbout'
import HomeServices from '../components/Home/Services/HomeServices'
import HomeSectors from '../components/Home/Sectors/HomeSectors'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      wp {
        page(id: "2", idType: DATABASE_ID) {
          seo {
            title
          }
          homePage {
            hero {
              button1
              button2
              desc
              title
              subtitle
            }
            about {
              img {
                sourceUrl
                altText
                mediaDetails {
                  width
                  height
                }
              }
              text
              link {
                title
                url
              }
            }
            services {
              title
              services {
                title
                desc
                link {
                  title
                  url
                }
                icon {
                  sourceUrl
                  mediaDetails {
                    width
                    height
                  }
                }
              }
            }
            sectors {
              title
              sectors {
                text
                icon {
                  sourceUrl
                  mediaDetails {
                    width
                    height
                  }
                }
              }
            }
            clients {
              title
              logos {
                mobile {
                  sourceUrl
                  mediaDetails {
                    width
                    height
                  }
                  altText
                }
                tablet {
                  sourceUrl
                  mediaDetails {
                    width
                    height
                  }
                  altText
                }
                desktop {
                  sourceUrl
                  mediaDetails {
                    width
                    height
                  }
                  altText
                }
              }
            }
          }
        }
      }
    }
  `)

  const { seo } = data.wp.page
  const { hero, about, services, sectors, clients } = data.wp.page.homePage

  return (
    <>
      <Seo wpSeo={seo} />
      <HomeHero data={hero} />
      <HomeAbout data={about} />
      <HomeServices data={services} />
      <HomeSectors data={sectors} />
    </>
  )
}

export default IndexPage
